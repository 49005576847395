<template>
  <b-card
    class="h-100"
    :title="$t('tests.test_questions_part')"
  >
    <div
      v-if="!selections.length"
      class="d-flex"
      style="border: 2px dashed gray; height: calc(100% - 80px)"
    >
      <b-button
        v-b-modal.select-questions-modal
        class="mx-auto"
        variant="white"
      >
        <feather-icon
          size="150"
          icon="PlusIcon"
        />
      </b-button>
    </div>
    <div
      v-else
      style="height: calc(100% - 80px); overflow-y: auto"
    >
      <b-button
        variant="info"
        @click="$emit('settingsClick')"
      >
        <feather-icon icon="SettingsIcon" />
        {{ $t('tests.general_settings') }}
      </b-button>
      <b-button
        v-b-modal.select-questions-modal
        variant="warning"
        class="ml-1"
        @click="changeTests"
      >
        <feather-icon icon="EditIcon" />
        {{ $t('tests.change_tests') }}
      </b-button>
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="mt-2"
        style="border-bottom: 3px solid black;"
      >
        <test-preview
          v-if="question"
          :lesson-attachment="true"
          :questions-body="question"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import testPreview from '@/views/modules/Test/components/testPreview.vue'

export default {
  components: {
    BButton,
    BCard,
    testPreview,
  },
  props: {
    selections: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      questions: [],
    }
  },
  watch: {
    selections() {
      this.questions = []
      this.refresh()
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('questions', ['FETCH_ONE_QUESTION']),
    refresh() { //! есть ошибка не правильно работает
      const data = []
      this.selections.forEach(async (element, index) => {
        const q = await this.FETCH_ONE_QUESTION(element)
        this.$set(data, index, q)
        // data.push({ ...q, index })
      })
      this.questions = data

      // ! исправил но я не уверен
    },
    changeTests() {
      const q = this.questions.map(({ id, title }) => ({ id, title }))
      // console.log(q.map(e => e.id), 'before')
      this.$nextTick(() => {
        this.$emit('changeTests', q)
      })
    },
  },
}
</script>

<style>

</style>
