<template>
  <b-modal
    id="test-lesson-settings-modal"
    :title="$t('tests.general_settings')"
    size="lg"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="saveClick"
    @shown="onShow"
  >
    <b-row>
      <b-col md="6">
        <b-form-group
          :label="$t('tests.test_report_form')"
          label-for="test-report-form"
        >
          <v-select
            id="test-report-form"
            v-model="options.report"
            :options="$data.$_test_report_form_options"
            :get-option-label="opt => opt.label[$i18n.locale]"
            :reduce="opt => opt.key"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <!-- <b-form-group
          :label="$t('tests.test_presentation_form')"
          label-for="test-presentation-form"
        >
          <v-select
            id="test-presentation-form"
            v-model="options.presentation"
            :options="$data.$_test_presentation_options"
            :get-option-label="opt => opt.label[$i18n.locale]"
            :reduce="opt => opt.key"
          />
        </b-form-group> -->
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('courses.lessons_order_type')"
          label-for="lesson_order_type"
        >
          <v-select
            v-model="options.order_type"
            :options="$data.$_lessons_view_order"
            :reduce="opt => opt.key"
            :get-option-label="opt => opt.title[$i18n.locale]"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('tests.attempts')"
          label-for="attempts"
        >
          <b-form-input
            id="attempts"
            v-model="options.attempts"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('tests.time_min')"
          label-for="time_min"
        >
          <b-form-input
            id="time_min"
            v-model="options.time"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('tests.passing_score')"
          label-for="passing_score"
        >
          <b-form-input
            id="passing_score"
            v-model="options.passing_score"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <label>{{ $t('tests.test_result_descriptions') }}</label>
        <b-row
          v-for="(description, index) in options.result_descriptions"
          :key="index"
        >
          <b-col md="4">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="description.start"
                  class="mr-50"
                />
                <span> - </span>
                <b-form-input
                  v-model="description.end"
                  class="ml-50"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group>
              <div
                class="d-flex align-items-start"
              >
                <b-form-input v-model="description.text" />
                <b-button
                  variant="danger"
                  @click="removeDescription(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <b-button
            variant="info"
            @click="addNewDescription"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('tests.add_new_description') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    defOptions: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      new_audio_track: null,
      options: this.defOptions,
    }
  },
  watch: {
    defOptions() {
      this.options = this.defOptions
    },
  },
  methods: {
    ...mapActions('content', ['FETCH_ONE_FOLDER']),
    saveClick() {
      this.$emit('save', this.options)
    },
    addNewDescription() {
      this.options.result_descriptions.push({ start: '', end: '', text: '' })
    },
    removeDescription(index) {
      this.options.result_descriptions.splice(index, 1)
    },
    onShow() {
      if (this.defOptions.audio_track) {
        this.FETCH_ONE_FOLDER(this.defOptions.audio_track).then(res => {
          this.new_audio_track = { name: res.file.split('/').reverse()[0] }
        })
      }
    },
  },
}
</script>

<style>

</style>
