<template>
  <b-card
    class="h-100"
    :title="$t('tests.test_body')"
  >
    <b-form-checkbox
      v-model="is_file"
      name="check-button"
      switch
      inline
      @input="$emit('update:isContentFile', $event)"
    >
      {{ $t("courses.file") }}
    </b-form-checkbox>
    <b-button
      v-if="content && is_file"
      v-b-modal.select-content-modal
      variant="warning"
    >
      {{ $t("general.change_file") }}
    </b-button>
    <div
      v-if="is_file"
      class="pb-2"
      style="height: calc(100% - 80px)"
    >
      <div
        v-if="!content"
        class="h-100 mt-2 d-flex"
        style="border: 2px dashed gray"
      >
        <b-button
          v-b-modal.select-content-modal
          class="mx-auto"
          variant="white"
          @click="openContentSelectModal"
        >
          <feather-icon
            size="150"
            icon="PlusIcon"
          />
        </b-button>
      </div>
      <div
        v-else
        class="h-100 mt-2 overflow-auto"
      >
        <all-doc-viewer :src="content" />
      </div>
    </div>
    <div
      v-else
      id="test-body"
      class="mt-1 pb-4"
      style="height: calc(100% - 110px)"
    >
      <!-- <quill-editor
        v-model="description"
        class="mb-1 h-100"
        @input="$emit('update:defDescription', $event)"
      /> -->
      <ckeditor
        v-model="description"
        class="px-1"
        @input="$emit('update:defDescription', $event)"
      />
    </div>
  </b-card>
</template>

<script>
import { BFormCheckbox, BCard, BButton } from 'bootstrap-vue'
import allDocViewer from '@/components/AllDocViewer.vue'
import customDocEditor from '@/components/documentCKEditor.vue'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BButton,
    allDocViewer,
    // quillEditor,
    ckeditor: customDocEditor,
  },
  props: {
    defContent: {
      type: String,
      required: false,
      default: '',
    },
    defDescription: {
      type: String,
      required: false,
      default: '',
    },
    isContentFile: {
      type: Boolean,
      required: false,
      default: true,
    },
    part: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      is_file: true,
      selected_file: '',
      content: this.defContent,
      description: this.defDescription,
      editorConfig: {
        ui: {
          viewportOffset: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          },
        },
      },
    }
  },
  watch: {
    defContent() {
      this.content = this.defContent
    },
    defDescription() {
      this.description = this.defDescription
    },
    isContentFile() {
      this.is_file = this.isContentFile
    },
  },
  mounted() {
    this.is_file = this.isContentFile
  },
  methods: {
    openContentSelectModal() {},
  },
}
</script>

<style lang="scss">
#test-body {
  .ck-editor__main,
  .ck-editor,
  .ck-content {
    height: 100%;
  }
}
</style>
