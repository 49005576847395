<template>
  <b-modal
    id="test-part-modal"
    centered
    :title="isEdit ? $t('tests.edit_test_part') : $t('tests.add_test_part')"
    :cancel-title="$t('general.cancel')"
    :ok-title="isEdit ? $t('general.save') : $t('general.add')"
    @ok.prevent="saveClick"
  >
    <validation-observer ref="testPartValidations">
      <b-form-group :label="$t('general.title')">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input v-model="part.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="part.options.content"
          inline
          switch
        >
          {{ $t('tests.test_body') }}
        </b-form-checkbox>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    defPart: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      part: {
        title: '',
        draft_id: null,
        options: {
          content: true,
        },
      },
    }
  },
  watch: {
    defPart() {
      this.part = this.defPart
    },
  },
  methods: {
    async saveClick() {
      const success = await this.$refs.testPartValidations.validate()
      if (!success) return
      this.$emit('saveSuccess', this.part)
    },
  },
}
</script>

<style>

</style>
