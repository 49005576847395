<template>
  <div>
    <!-- <pre>
      {{ parts }}
    </pre> -->
    <b-tabs
      v-model="active_tab"
      lazy
    >
      <template #tabs-start>
        <!-- v-b-modal.test-lesson-settings-modal -->
        <b-button
          class="py-50 mr-2 px-1"
          variant="info"
          @click="openTestSettingsModal"
        >
          <feather-icon
            size="24"
            icon="SettingsIcon"
          />
        </b-button>
      </template>
      <template #tabs-end>
        <b-button
          v-b-modal.test-part-modal
          class="py-50 ml-2 px-1"
          variant="outline-primary"
          @click="openNewPartModal"
        >
          <feather-icon
            size="24"
            icon="PlusIcon"
          />
        </b-button>
      </template>
      <template v-for="(part, index) in parts">
        <b-tab
          v-if="!part.is_deleted"
          :key="index"
        >
          <!-- {{ part.questions }} -->
          <template #title>
            {{ part.title }}
            <b-button
              v-b-modal.test-part-modal
              class="p-50 ml-50"
              variant="white"
              @click="editPart(part, index)"
            >
              <feather-icon
                class="text-warning mr-0"
                icon="EditIcon"
              />
            </b-button>
            <b-button
              class="p-50"
              variant="white"
            >
              <feather-icon
                class="text-danger mr-0"
                icon="XIcon"
                @click="removePart(index, part.id)"
              />
            </b-button>
          </template>
          <!-- <b-row>
          <b-col md="6">
            <b-card :title="$t('tests.test_body')" />
          </b-col>
          <b-col md="6" />
        </b-row> -->
          <div style="height: calc(100vh - 255px)">
            <custom-dragbar
              v-if="part.options.content"
              class="h-100"
            >
              <template #left-side>
                <div class="p-1 h-100">
                  <test-body-formation
                    :def-content="part.content_url"
                    :part="part"
                    :is-content-file.sync="part.options.is_file"
                    :def-description.sync="part.description"
                  />
                </div>
              </template>
              <template #right-side>
                <div class="p-1 h-100">
                  <test-questions-formation
                    ref="questionsFormation"
                    :selections="part.questions"
                    @settingsClick="openSettingsModal(part)"
                    @changeTests="changeSelectedTests"
                  />
                </div>
              </template>
            </custom-dragbar>
            <b-card
              v-else
              class="h-100"
            >
              <test-questions-formation
                :selections="part.questions"
                @settingsClick="openSettingsModal(part)"
                @changeTests="changeSelectedTests"
              />
            </b-card>
          </div>
        </b-tab>
      </template>
    </b-tabs>
    <div>
      <b-button
        v-if="
          $can('manage', 'super_admin') ||
            $can('manage', 'head_manager') ||
            $can('manage', 'content_manager')
        "
        variant="primary"
        @click="saveClick"
      >
        {{ $t("general.save") }}
      </b-button>
      <b-button
        class="ml-1"
        @click="
          $router.push(
            `/course/course-administrations/theme-list/${GET_ONE_COURSE.id}`
          )
        "
      >
        {{ $t("general.cancel") }}
      </b-button>
    </div>
    <test-part-modal
      :is-edit="part_edit"
      :def-part="selected_part"
      @saveSuccess="addNewPart"
    />
    <select-questions-modal
      :def-selected-questions="questions_to_change"
      @saveClick="questionsSelected"
    />
    <content-select-modal @fileSelected="fileSelected" />
    <test-settings-modal
      :def-options="selected_part.options"
      @save="settingsSave"
    />
    <test-lesson-settings-modal
      :def-options="lesson_options"
      @save="lessonSettingsSaved"
    />
  </div>
</template>

<script>
import {
  BButton, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import customDragbar from '@/components/customDragbar.vue'
import testBodyFormation from './components/testBodyFormation.vue'
import testPartModal from './components/modals/addOrEditPartModal.vue'
import testQuestionsFormation from './components/testQuestionsFormation.vue'
import selectQuestionsModal from './components/modals/selectQuestionsModal.vue'
import contentSelectModal from './components/modals/contentSelectModal.vue'
import testSettingsModal from './components/modals/testSettingsModal.vue'
import testLessonSettingsModal from './components/modals/testLessonSettingsModal.vue'

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    customDragbar,
    testBodyFormation,
    testQuestionsFormation,
    testPartModal,
    selectQuestionsModal,
    contentSelectModal,
    BCard,
    testSettingsModal,
    testLessonSettingsModal,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      parts: [
        {
          title: 'Part 1',
          description: '',
          contents: [],
          options: {
            content: true,
          },
          content_url: '',
        },
      ],
      lesson_options: {
        report: 'score',
        passing_score: 0,
      },
      selected_part: {
        title: '',
        options: {},
      },
      deleted_parts: [],
      questions_to_change: [],
      is_edit: true,
      part_edit: false,
      active_tab: null,
    }
  },
  computed: {
    ...mapGetters('courses', [
      'FETCH_ONE_CONTENT',
      'GET_LESSON',
      'GET_ONE_CHAPTER',
      'GET_ONE_COURSE',
    ]),
  },
  async mounted() {
    await this.FETCH_LESSON(this.$route.params.id)
    this.parts = (
      this.GET_LESSON.lesson_relation_draft?.length
        ? this.GET_LESSON.lesson_relation_draft
        : this.GET_LESSON.lesson_relation
    ).map(el => {
      const { contents, ...rest } = el
      return {
        contents: contents.map(cont => cont.id),
        content_url: contents.length ? contents[0].file : undefined,
        ...rest,
      }
    })
    this.lesson_options = this.GET_LESSON.options
    // this.FETCH_ONE_CONTENT_FILE(rest.option.audio_track)
    await this.FETCH_ONE_CHAPTER(this.GET_LESSON.chapter)
    await this.FETCH_ONE_COURSE(this.GET_ONE_CHAPTER.course)
    this.updateBreadcrumb()
  },
  methods: {
    ...mapActions('courses', [
      'CREATE_LESSON_CONTENT',
      'FETCH_LESSON',
      'FETCH_ONE_CHAPTER',
      'FETCH_ONE_COURSE',
      'UPDATE_LESSON',
      'UPDATE_COURSE_RELATION',
    ]),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    openNewPartModal() {
      this.part_edit = false
      this.selected_part = {
        title: '',
        contents: [],
        options: {
          content: true,
          is_file: true,
        },
      }
    },
    updateBreadcrumb() {
      const [first, second, third, fourth, fifth] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_ONE_COURSE.title
      second.to = `/course/course-administrations/theme-list/${this.GET_ONE_COURSE.id}`
      third.text = this.GET_ONE_CHAPTER.title
      fourth.text = this.GET_LESSON.title
      this.UPDATE_BREADCRUMB([first, second, third, fourth, fifth])
    },
    addNewPart(part) {
      if (!this.part_edit) {
        this.parts.push(part)
        this.$bvModal.hide('test-part-modal')
      } else {
        this.$set(this.parts, this.part_index, part)
        // this.parts[this.part_index] = part
        this.$bvModal.hide('test-part-modal')
      }
    },
    editPart(part, index) {
      this.part_edit = true
      this.selected_part = this.$_removeObjectConnection(part)
      this.part_index = index
    },
    questionsSelected(questions) {
      console.log(questions, 'after')
      this.$set(
        this.parts[this.active_tab],
        'questions',
        questions,
      )
      // this.parts[this.active_tab].questions = questions
      // this.questions_to_change = questions
      this.$nextTick(() => {
        this.$bvModal.hide('select-questions-modal')
      })
    },
    fileSelected(item) {
      this.$set(this.parts[this.active_tab], 'contents', [item.id])
      this.$set(this.parts[this.active_tab], 'content_url', item.url)
      // this.parts[this.active_tab].contents = []
      this.$bvModal.hide('select-content-modal')
    },
    openSettingsModal(part) {
      this.selected_part = part
      this.$nextTick(() => {
        this.$bvModal.show('test-settings-modal')
      })
    },
    openTestSettingsModal() {
      if (
        !this.lesson_options.result_descriptions
        || !this.lesson_options.result_descriptions.length
      ) {
        this.lesson_options.result_descriptions = [
          { start: 0, end: 100, text: '' },
        ]
      }
      this.$nextTick(() => {
        this.$bvModal.show('test-lesson-settings-modal')
      })
    },
    settingsSave(options) {
      this.selected_part.options = options
      this.$nextTick(() => {
        this.$bvModal.hide('test-settings-modal')
      })
    },
    changeSelectedTests(questions) {
      this.questions_to_change = questions
    },
    removePart(index, id) {
      // this.parts.splice(index, 1)
      this.$set(this.parts[index], 'is_deleted', true)
      this.deleted_parts.push(id)
    },
    lessonSettingsSaved(opts) {
      this.lesson_options = opts
      this.$nextTick(() => {
        this.$bvModal.hide('test-lesson-settings-modal')
      })
    },
    saveClick() {
      // const {  } = this.parts
      // const reqBody = {}
      // this.CREATE_LESSON_CONTENT({ ids: this.deleted_parts, is_active: false })
      this.UPDATE_LESSON({
        id: this.$route.params.id,
        options: this.lesson_options,
      })
      const reqBody = this.deleted_parts.length
        ? {
          lesson: this.$route.params.id,
          objects: this.parts,
          ids: this.deleted_parts,
          is_active: false,
        }
        : { lesson: this.$route.params.id, objects: this.parts }
      this.UPDATE_COURSE_RELATION(reqBody).then(() => {
        // this.CREATE_LESSON_CONTENT(reqBody).then(() => {
        this.$_successToast(this.$t('general.added'))
        this.$router.push({
          name: 'theme-list',
          params: { id: this.GET_ONE_COURSE.id },
        })
      })
    },
  },
}
</script>

<style></style>
